html, body {
    padding: 0;
    margin: 0;
    max-width: 100%;
    font-family: "Rubik", Arial, "Helvetica Neue", Helvetica, sans-serif;
    background:  #f2f2f8;
    overflow-y: initial; }
.section_title {
    color: #5666b4;
    font-family: Rubik;
    font-size: 25px;
    font-weight: 700;
    line-height: 35px;
    text-transform: uppercase;
    letter-spacing: 0.5px;
    position: relative;
    padding: 20px 0 20px 40px;
    margin-bottom: 0;
    &:before {
        content: "";
        background: url('../../img/road.png') center no-repeat;
        position: absolute;
        left: 0;
        top: 20px;
        display: inline-block;
        width: 31px;
        height: 37px; } }
img {
    width: 100%;
    height: auto;
    display: block; }

a {
    color: inherit;
    transition: color 200ms; }


.btn-danger {
    color: #ffffff;
    font-family: "Exo 2";
    font-size: 12px;
    font-weight: 700;
    line-height: 15.56px;
    text-transform: uppercase;
    box-shadow: 0 8px 7px 1px rgba(241, 126, 0, 0.34);
    border-radius: 5px;
    background-color: #ff1d00;
    letter-spacing: 0.37px;
    padding: 13px 20px; }

$sm: 576px;
$md: 768px;
$lg: 992px;
$elg: 1200px;
@mixin sm {
  @media (min-width: #{$sm}) {
    @content; } }
@mixin md {
  @media (min-width: #{$md}) {
    @content; } }
@mixin lg {
  @media (min-width: #{$lg}) {
    @content; } }
@mixin elg {
  @media (min-width: #{$elg}) {
    @content; } }
