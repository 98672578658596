.autopark {
    @include lg {
        padding: 60px 0 46px;
        background: #f2f2f8 url('../../img/slide6.png') 458px center no-repeat; }
    @include elg {
        background: #f2f2f8 url('../../img/slide6.png') 618px center no-repeat; }
    .section_title {
        padding: 19px 0 13px 40px; } }
.form_wrapper {
    @include lg {
        width: 83%;
        margin: 0 auto;
        display: flex; }
    .time-bg {
        color: #5666b4;
        font-size: 20px;
        font-weight: 700;
        line-height: 25px;
        background: url('../../img/calculator-mob.png') 188px bottom no-repeat;
        padding-bottom: 22%;
        @include sm {
            width: 80%;
            margin: 0 auto 30px;
            padding-bottom: 10%; }

        @include lg {
            background: url('../../img/calculator-icon.png') 125px bottom no-repeat;
            margin-right: 123px;
            width: 50%; }
        @include elg {
            background: url('../../img/calculator-mob.png') 238px bottom no-repeat;
            padding-left: 83px; }


        h4 {
            padding: 20px 0;
            @include lg {
                padding: 7px 0 0 66px; } }
        .minute-n {
            display: inline-block;
            color: #ff1d00;
            font-size: 120px;
            font-weight: 700;
            line-height: 95px;
            letter-spacing: 2.4px;
            @include lg {
                padding-left: 65px; } }
        .minute-t {
            display: inline-block;
            color: #ff1d00;
            font-size: 40px;
            font-weight: 700;
            line-height: 25px;
            letter-spacing: 0.8px;
            vertical-align: 34px; } }
    .form {

        box-shadow: 0 5px 17px 4px rgba(206, 206, 206, 0.15);
        border-radius: 5px;
        border: 2px solid #ff8787;
        background-color: #ffffff;
        padding: 26px 30px;
        @include sm {
            width: 80%;
            margin: 0 auto; }
        @include lg {
            width: 40%;
            padding: 26px 60px; }
        &_inner {
            display: flex;
            flex-direction: column; }
        &_title {
            color: #4e4e4d;
            font-family: Rubik;
            font-size: 14px;
            font-weight: 300;
            line-height: 18px;
            text-align: center;
            margin-bottom: 22px; }
        input {
            color: #979797;
            font-family: "Exo 2";
            font-size: 12px;
            font-weight: 300;
            line-height: 15.56px;
            letter-spacing: 0.37px;
            padding: 13px 20px 13px 40px;
            box-shadow: inset 0 3px 9px 1px rgba(0, 0, 0, 0.1);
            border-radius: 5px;
            border: 1px solid #e0e5e9;
            margin-bottom: 10px; }

        &_contact {
            box-shadow: 0 5px 17px 4px rgba(206, 206, 206, 0.15);
            border-radius: 5px;
            border: 2px solid #ff8787;
            padding: 10px 30px;
            text-align: center;
            margin-top: 15px;
            span {
                display: block;
                color: #4e4e4d;
                font-family: Rubik;
                font-size: 10px;
                font-weight: 300;
                line-height: 17px; }
            .phone {
                color: #2a2a2a;
                font-size: 16px;
                display: block;
                margin-bottom: 10px; } } } }
.roadmap_wrapper {
    padding: 82px 15px 40px; }
.roadmap {
    background: #f2f2f8 url('../../img/roadmap-mob.png') center center no-repeat;
    padding: 23% 0;
    @include lg {
        background: #f2f2f8 url('../../img/roadmap.png') center center no-repeat;
        background-size: cover; }
    &_countries {
        display: flex;
        flex-wrap: wrap; } }

.country {
    width: 32%;
    background: url('../../img/country-marker.png') center center no-repeat;
    display: inline-block;
    padding-top: 23%;
    text-align: center; }
.soc_icon {
    display: inline-block;
    width: 22px;
    height: 22px; }

.whatsapp {
    background: url('../../img/whatsapp.svg') center no-repeat;
    margin-right: 12px; }
.telegram {
    background: url('../../img/telegram.svg') center no-repeat; }
