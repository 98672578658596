@import "includes/base";
@import "includes/bs-grid/grid";
@import "includes/variables";
@import "includes/header";
@import "includes/about";
@import "includes/best";
@import "includes/autopark";
@import "includes/contact";

h1 {
  font-weight: 300;
  text-align: center;
  font-size: 60px; }
ul {
  padding: 0;
  font-size: 1.4rem;
  list-style-type: none; }


.including {
  font-weight: bold;
  text-shadow: 3px 5px 2px #474747;
  text-transform: uppercase;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  margin-bottom: 0; }


.avatar {
  width: 100%; }
