#about {
    background-color: #f2f2f8; }
.about {
    padding: 62px 0 46px;
    &-row {
        padding: 102px 0 73px 0;
        box-shadow: 0 16px 21px rgba(205, 226, 255, 0.24);
        border-radius: 15px;
        background-color: #ffffff;
        position: relative;
        &::before {
            position: absolute;
            width: 100%;
            height: 90%;
            z-index: -1;
            top: 0;
            left: 0;
            box-shadow: 0 16px 21px rgba(205, 226, 255, 0.24);
            border-radius: 15px;
            background-color: #ffffff; } }
    &-row2 {
        background-color: #ffffff;
        position: relative;
        padding: 40px 0 3% 0; }
    &-row.mb-5 {
        margin-bottom: 16%!important; }
    .about_img {
        background: url("../../img/about-img.png") center no-repeat;
        padding: 37% 0;
        width: 100%;
        @include md {
            position: absolute;
            top: -250px;
            left: 5px; } }
    &_title {
        color: #5666b4;
        font-size: 20px;
        font-weight: 700;
        line-height: 30px;
        letter-spacing: 0.4px;
        position: relative;
        margin-top: 20px;
        margin-bottom: 20px;
        @include md {
            margin-top: 0; }
        &::before {
            content: "";
            background: url('../../img/quote.png') center no-repeat;
            position: absolute;
            left: -44px;
            top: -7px;
            display: inline-block;
            width: 31px;
            height: 37px; } }
    &_text {
        color: #767676;
        font-family: Rubik;
        font-size: 15px;
        font-weight: 300;
        line-height: 25px; }
    &_icons {
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        align-items: center;
        @include sm {
            flex-wrap: wrap;
            flex-direction: row; } }
    &_icon {
        text-align: center;
        @include sm {
            width: 45%; }
        @include md {
            width: 20%; }
        span {
            padding: 74px;
            display: inline-block; }
        .f1 {
            background: url('../../img/features1.png') center no-repeat; }
        .f2 {
            background: url('../../img/features2.png') center no-repeat; }
        .f3 {
            background: url('../../img/features3.png') center no-repeat; }
        .f4 {
            background: url('../../img/features4.png') center no-repeat; }
        .f5 {
            background: url('../../img/features5.png') center no-repeat; } } }

