.header {
    background: url('../../img/promo.jpg') center no-repeat;
    background-size: cover;
    .address {
        color: #fff;
        padding-top: 20px;
        background: url('../../img/placeholder_mob.png') left center no-repeat;
        border-top: 1px solid #333740;
        margin-top: 15px; } }
.navbar-expand-lg .navbar-nav {
    width: 100%;
    align-items: center;

    @include lg {
        border-bottom: none; } }
.navbar {
    padding: 15px 0;
    @include lg {
        margin: 0 0 59px 0; } }
.navbar-toggler {
    background: url('../../img/icon-menu-white.png') center no-repeat;
    &:focus {
        outline: none; } }
.navbar-brand {
    background: url('../../img/logo.png') center no-repeat;
    background-size: contain;
    display: inline-block;
    width: 150px;
    height: 42px;
    @include lg {
        width: 200px;
        height: 52px;
        background-size: cover; } }

.navbar-contact {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: center;
    background: url('../../img/header-call.png') 13px center no-repeat; }

.navbar_text-sm {
    color: #ffffff;
    font-size: 12px;
    font-weight: 300;
    line-height: 15px;
    margin-top: 15px;
    @include lg {
        margin-top: 0; } }
.navbar-expand-lg .navbar-nav .nav-link {
    color: #ffffff;
    font-size: 18px;
    font-weight: 500;
    line-height: 25px;
    padding: 19px 12px 0 12px; }
.navbar .btn-primary {
    color: #ffffff;
    font-size: 14px;
    font-weight: 500;
    line-height: 25px;
    border-radius: 4px;
    background-color: #0057ff;
    padding: 10px 23px;
    display: inline-block;
    margin-top: 10px; }
.navbar-collapse {
    background: #000;
    margin-top: 10px;
    padding: 0 30px 40px;
    @include lg {
        margin-top: 0;
        background: transparent;
        padding: 0; } }
@media (min-width: 992px) {
    .navbar-expand-lg .navbar-toggler {
        display: none; } }
.menu {
    border-radius: 4px;
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-top: 10px;
    border-bottom: 1px solid #333740;
    @include lg {
        position: absolute;
        top: 98px;
        left: 0;
        justify-content: center;
        flex-direction: row;
        border: 3px solid #5e5c5c; }

    &_link {
        color: #ffffff;
        font-family: Rubik;
        font-size: 16px;
        font-weight: 700;
        line-height: 32px;
        padding: 0 18px;
        &:hover {
            color: #5566b4;
            text-decoration: none; }
        @include lg {
            padding: 9px 18px; } } }
.lng {
    background: url('../../img/lng.png') center no-repeat;
    display: inline-block;
    width: 23px;
    height: 16px;
    position: absolute;
    top: 26px;
    right: 82px;
    @include lg {
        right: 30px;
        top: 127px; } }

.promo {
    color: #ffffff;
    padding: 13% 0;

    &_title {
        color: #ffffff;
        font-family: Rubik;
        font-size: 28px;
        font-weight: 700;
        line-height: 43px;
        text-transform: uppercase;
        background: #ff2b18;
        display: inline-block;
        span {
            background: #5666b4;
            margin-bottom: 0;
            display: inline-block; }
        @include md {
            font-size: 40px;
            line-height: 52px; } }
    &_title-sm {
        font-size: 15px;
        font-weight: 300;
        line-height: 25px; }
    &_items {
        display: flex;
        flex-wrap: wrap;
        @include md {
            width: 50%; } }
    &_item {
        padding: 31px 0 3px 62px;
        @include sm {
            width: 45%; } }
    &_item.clock {
        background: url('../../img/24-hours.png') 8px 35px no-repeat; }
    &_item.marker {
        background: url('../../img/marker.png')  8px 35px no-repeat; }
    &_item.check {
        background: url('../../img/check-mark.png') 8px 35px no-repeat; }
    &_item.delivery {
        background: url('../../img/clock.png') 8px 35px no-repeat; } }
