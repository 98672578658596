.contact {
    margin: 0 auto;
    padding: 27px 0;
    @include sm {
        width: 80%; }
    @include elg {
        width: 60%; }
    &_form {
        border-radius: 4px;
        background-color: #5666b4;
        text-align: center;
        padding: 20px 30px 30px;
        position: relative;
        z-index: 999;
        @include lg {
            padding: 20px 60px 30px; }

        &-title {
            color: #ffffff;
            font-family: Rubik;
            font-size: 18px;
            font-weight: 700;
            line-height: 25px;
            margin-bottom: 20px; }
        input {
            border-radius: 4px;
            background-color: #5666b4;
            padding: 12px 20px;
            border: 1px solid #fff;
            font-size: 12px;
            font-weight: 300;
            width: 100%;
            margin-bottom: 10px;
            @include elg {
                width: 49%; }
            &::placeholder {
                color: #ffffff;
                font-family: Rubik;
                font-size: 12px;
                font-weight: 300;
                letter-spacing: 0.37px; }
            &:focus {
                color: #fff; } }
        .btn-danger {
            box-shadow: none;
            width: 100%;
            @include elg {
                width: 49%; } } }
    &_list {
        box-shadow: 0 5px 17px 4px rgba(0, 0, 0, 0.15);
        border-radius: 4px;
        background-color: #ffffff;
        padding: 20px 30px;
        width: 260px;
        z-index: 999;
        position: absolute;
        top: 20px;
        left: 10px;
        @include lg {
            top: 110px;
            left: 82px;
            width: 300px; } }
    &_title {
        color: #5666b4;
        font-family: Rubik;
        font-size: 19px;
        font-weight: 700;
        line-height: 20px;
        text-transform: uppercase;
        position: relative;
        @include lg {
            padding: 10px 0 53px 73px; }
        &::after {
            @include lg {
                content: "";
                position: absolute;
                right: 41px;
                top: -16px;
                background: url('../../img/cup.png') center no-repeat;
                width: 100px;
                height: 100px;
                background-size: cover; } } }
    &_logo {
        background: url(../../../../img/logo1.png) center no-repeat;
        display: inline-block;
        background-size: cover;
        width: 146px;
        height: 38px;
        margin-bottom: 18px; } }
.map_wrapper {
    position: relative;
    .phone {
        display: block;
        font-size: 16px;
        font-weight: 500;
        line-height: 25px;
        color: #3b3b3b;
        background: url('../../img/call.png') left center no-repeat;
        padding-left: 30px;
        span {
            color: #e50000; } }
    .worktime {
        small {
            color: #262626;
            font-size: 14px;
            font-weight: 300;
            line-height: 25px;
            display: block; } }
    .phone_whatsapp {
        background: url('../../img/whatsapp.svg') left center no-repeat;
        display: inline-block;
        padding-left: 30px;
        margin-bottom: 10px; }
    .worktime {
        background: url('../../img/clock_contact.png') left center no-repeat;
        margin-bottom: 10px;
        padding-left: 30px;
        display: block; } }
.address {
    color: #262626;
    font-size: 16px;
    font-weight: 500;
    line-height: 25px;
    padding-left: 30px;
    display: block;
    background: url('../../img/placeholder.png') left center no-repeat;
    margin-bottom: 10px; }

#map {
    height: 518px;
    @include lg {
        margin-top: -85px; } }
.footer {
    display: flex;
    justify-content: space-between;
    padding: 20px 0;
    flex-direction: column;
    @include lg {
        flex-direction: row; }
    &_logo {
        background: url(../../../../img/logo1.png) center no-repeat;
        display: inline-block;
        background-size: cover;
        width: 146px;
        height: 38px;
        margin-bottom: 20px; }
    &_text {
        color: #767676;
        font-size: 12px;
        font-weight: 300;
        line-height: 15px;
        letter-spacing: 0.24px; }
    .design {
        color: #272727;
        font-family: Rubik;
        font-size: 12px;
        font-weight: 300;
        line-height: 15px;
        span {
            color: #ff0000;
            text-transform: uppercase; }
        i {
            color: #262626;
            text-transform: uppercase;
            font-style: normal; } } }
