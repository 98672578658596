.features {
    padding: 9% 0 46px;
    &_list {
        padding: 30px 0; }
    img {
        @include sm {
            width: 50%; }
        @include lg {
            width: 100%; } }
    &_title {
        color: #000000;
        font-size: 18px;
        font-weight: 700;
        letter-spacing: 0.36px;
        line-height: 25px;
        padding-top: 16px; }
    &_text {
        font-weight: 300;
        letter-spacing: 0.3px;
        line-height: 24px;
        margin-bottom: 33px; }
    .btn-outline-danger {
        min-width: 200px;
        padding: 14px 28px 13px 15px;
        font-size: 14px;
        background: url("../../img/speech-bubble.png") 154px center no-repeat;
        margin-bottom: 50px;
        &:hover {
            background: #ff0000 url("../../img/speech-bubble-hover.png") 154px center no-repeat; } } }



